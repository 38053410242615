import React, { Fragment } from "react"
import { graphql, StaticQuery } from "gatsby"

export default (props) => (
    <StaticQuery
        query={graphql`
        {
            allMarkdownRemark(filter: {collection: {eq: "convegni"}}, sort: {order: DESC, fields: [frontmatter___date]}) {
                edges {
                    node {
                        frontmatter {
                            title
                            date(formatString: "MM DD, YYYY")
                            file_pdf
                            place
                        }
                        html
                    }
                }
            }
        }
    `}
        render={data => (

            data.allMarkdownRemark.edges.map(item =>
                props.anno != 'all'
                    ?
                    props.anno === item.node.frontmatter.date.split(', ')[1]
                        ?
                        <div key={item.node.frontmatter.title + item.node.frontmatter.date} className="tab-content" style={{ marginBottom: '30px' }}>
                            <h3>{item.node.frontmatter.title}</h3>
                            <p style={{ marginBottom: '1rem' }}><b>{item.node.frontmatter.date.split(", ")[1]}<br />
                            {item.node.frontmatter.place && item.node.frontmatter.place !== " " ?
                                <span>presso {item.node.frontmatter.place}</span>
                                :
                                null
                            }
                            </b></p>
                            {item.node.html ?
                                <div dangerouslySetInnerHTML={{ __html: item.node.html }}></div>
                                :
                                null
                            }
                            {item.node.frontmatter.file_pdf ?
                                <a style={{ marginTop: '20px', marginBottom: '30px' }} href={item.node.frontmatter.file_pdf} target="_blank" className="button bkg-pinterest border-hover-pinterest color-white color-hover-pinterest">
                                    <span></span> Scarica allegato</a>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    :
                    <div key={item.node.frontmatter.title + item.node.frontmatter.date} className="tab-content" style={{ marginBottom: '30px' }}>
                        <h3>{item.node.frontmatter.title}</h3>
                        <p style={{ marginBottom: '1rem' }}><b>{item.node.frontmatter.date.split(", ")[1]}<br />
                        {item.node.frontmatter.place && item.node.frontmatter.place !== " " ?
                            <span>presso {item.node.frontmatter.place}</span>
                            :
                            null
                        }
                        </b></p>
                        {item.node.html ?
                            <div dangerouslySetInnerHTML={{ __html: item.node.html }}></div>
                            :
                            null
                        }
                        {item.node.frontmatter.file_pdf ?
                            <a style={{ marginTop: '20px', marginBottom: '30px' }} href={item.node.frontmatter.file_pdf} target="_blank" className="button bkg-pinterest border-hover-pinterest color-white color-hover-pinterest">
                                <span></span> Scarica allegato</a>
                            :
                            null
                        }
                    </div>
            )
        )}
    />
)
