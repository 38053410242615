import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import '../css/pages/convegni.css'
import ConvegniLeftTemplate from '../templates/convegni/convegniLeftTemplate';
import ConvegniContainerTemplate from '../templates/convegni/convegniContainerTemplate';

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

const NewsPage = ({ pathContext: { locale } }) => (
	<>
		<Helmet
			bodyAttributes={{
				className: 'transition-support webkit convegni'
      }}
      title="News | Studio Legale Cirio - Francescon - Stella - Sartori - Maccari"
		>
		</Helmet>
		<Layout locale={locale}>
			<div className="convegni">
				<div className="content clearfix">
					<div className="section-block intro-title-2 header-image redFilter"></div>
					<div className="underHeader">
						<div className="item">
							<h1><FormattedMessage id="convegniMainTitle" /></h1>
						</div>
					</div>
					<div className="tabs style-2">
						<ul className="tab-nav cont-left">
							<li>
								<a href='#tutte'>Tutti</a>
							</li>
							<ConvegniLeftTemplate></ConvegniLeftTemplate>
						</ul>
						<div className="tab-panes cont-right">
							<ConvegniContainerTemplate all="1"></ConvegniContainerTemplate>
							<ConvegniContainerTemplate all="0"></ConvegniContainerTemplate>
						</div>
					</div>
				</div>
			</div>
		</Layout >
	</>
)

export default NewsPage
