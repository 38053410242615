import React, { Fragment } from "react"
import { graphql, StaticQuery } from "gatsby"

var anni = []
var anno_attuale = new Date()
anno_attuale = anno_attuale.getFullYear()

export default () => (
    <StaticQuery
        query={graphql`
        {
            allMarkdownRemark(filter: {collection: {eq: "convegni"}}, sort: {order: DESC, fields: [frontmatter___date]}) {
                edges {
                    node {
                        id
                        frontmatter {
                            date(formatString: "YYYY")
                        }
                    }
                }
            }
        }
    `}
        render={data => (
            data.allMarkdownRemark.edges.map(item => {
                if (anni.indexOf(item.node.frontmatter.date) == -1) {
                    anni.push(item.node.frontmatter.date)
                    return <li key={item.node.frontmatter.date} className={anno_attuale == item.node.frontmatter.date ? 'active' : ''}> <a href={'#' + item.node.id}>{item.node.frontmatter.date}</a> </li>
                } else {
                    return null
                }
            }
            )
        )}
    />
)
