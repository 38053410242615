import React from "react"
import { graphql, StaticQuery } from "gatsby"
import ConvegniTemplate from "./convegniTemplate";

var anni = []
var anno_attuale = new Date()
anno_attuale = anno_attuale.getFullYear()

export default (props) => (
    <StaticQuery
        query={graphql`
        {
            allMarkdownRemark(filter: {collection: {eq: "convegni"}}, sort: {order: DESC, fields: [frontmatter___date]}) {
                edges {
                    node {
                        id
                        frontmatter {
                            date(formatString: "YYYY")
                        }
                    }
                }
            }
        }
    `}
        render={data => (
            data.allMarkdownRemark.edges.map(item => {
                if (props.all == '0') {
                    if (anni.indexOf(item.node.frontmatter.date) === -1) {
                        anni.push(item.node.frontmatter.date)
                        return <div key={"parent-" + item.node.frontmatter.date} className={anno_attuale == item.node.frontmatter.date ? 'active animate-in' : ''} id={item.node.id}><ConvegniTemplate key={item.node.frontmatter.date + anno_attuale} anno={item.node.frontmatter.date} /></div>
                    } else {
                        return null
                    }
                } else {
                    return <div key={"parent-" + item.node.id} className={anno_attuale == item.node.frontmatter.date ? 'active animate-in' : ''} id='tutte'><ConvegniTemplate key={item.node.frontmatter.date + anno_attuale} anno='all' /></div>
                }
            }
            )
        )}
    />
)
